import React from "react";
import ReactDOM from "react-dom/client";

const root = ReactDOM.createRoot(document.getElementById("root"));

// Cast the string value of REACT_APP_LEGACY to a boolean
const isLegacy = process.env.REACT_APP_IS_LEGACY === "true";

// Conditionally import the appropriate version of App
let appModule;
if (isLegacy) {
  appModule = import("./App");
} else {
  appModule = import("./App");
  // appModule = import("./src/app/App");
}

appModule.then(module => {
  const App = module.default;
  root.render(<App />);
});
